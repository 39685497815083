import './app.scss';
import Navbar from './components/Navbar';
import { createContext, useEffect, useRef, useState } from 'react';
import Profile from './components/Profile/Profile';
import Footer from './components/Footer/Footer';
import Slider from './components/Slider/Slider';
import ContactMe from './components/ContactMe/ContactMe';

export const AppContext = createContext()

function App() {

  const [darkMode, setDarkMode] = useState(false)
  const [index, setIndex] = useState(null)
  const [data, setData] = useState([])

  const hasFetchedData = useRef(false)

  useEffect(() => {
    const FetchData = async () => {
      hasFetchedData.current = true
      try {
        const res = await fetch("https://server.tautvydas.net/projects.php", {
          method: "GET" 
        })
        const projects = await res.json()
        setData(projects)
      } catch (error) {
        alert(`Ups...\n ${error.message}`)
      } finally {
        hasFetchedData.current = false
      }
    }
    if (!hasFetchedData.current) {
      FetchData()
    }
  }, [])

  const targetElement = useRef()


   const scrollToIndex = (index) => {
      const list = targetElement.current
      const listItem = list.querySelectorAll('h2')[index]
      listItem.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      })
    }


  useEffect(() => {
    if (index !== null) {
      scrollToIndex(index)
    }
    setIndex(null)
  }, [index])


  return (
    <AppContext.Provider value={{ darkMode, setDarkMode, index, setIndex, data }}>
      <div className={`App ${darkMode ? "dark-mode" : null}`}>
        <Navbar /> 
        <div className='title big-padding'>
          <h1>{"<Web developer />"}</h1>
        </div>
        <div className='menu-items' ref={targetElement}>
          <Profile />
          <Slider />
          <ContactMe />
        </div>        
        <Footer />
      </div>
    </AppContext.Provider>
  );
}

export default App;
