import React, { useContext, useState } from 'react'
import './navbar.scss'
import ThemeBtn from './ThemeBtn/ThemeBtn'
import { AppContext } from '../App'
import ClickOutside from './ClickOutside/ClickOutside'

const Navbar = () => {
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const index = useContext(AppContext)
    
    
  return (
    <nav className='navbar'>
        <div className="container">
                <div className="hamburger-btn">
                    <input id='toggle-btn' type="checkbox" onChange={() => setMenuIsOpen(!menuIsOpen)}/>
                    <label className='btn-container' htmlFor="menu-toggle">
                    <div className="menu-btn"></div>
                    </label>
                </div>
            <ClickOutside className="clickOutside" onClick={() => {return [setMenuIsOpen(false), document.getElementById("toggle-btn").checked = false]}}>
                <div className="quote-container">
                    <div className={`nav-list ${menuIsOpen ? " open note sticky" : null}`}>
                        <ul>
                            <li onClick={() => {return [index.setIndex(0), setMenuIsOpen(false), document.getElementById("toggle-btn").checked = false]}}>
                                <span>About me</span>
                            </li>
                            <li onClick={() => {return [index.setIndex(1), setMenuIsOpen(false), document.getElementById("toggle-btn").checked = false]}}>
                                <span >Projects</span>
                            </li>
                            <li onClick={() => {return [index.setIndex(2), setMenuIsOpen(false), document.getElementById("toggle-btn").checked = false]}}>
                                <span>Contact me</span>
                            </li>
                        </ul>
                    </div>     
                </div> 
            </ClickOutside>
            <div className="toggle-mode">
                <ThemeBtn />
            </div>
        </div>
    </nav>
  )
}

export default Navbar