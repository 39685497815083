export const projects = [
    {
        title: 'Web Shop',
        desc: "This is a full-stack e-commerce website. You can search for products by typing the product name or brand. On the products page, you can sort and filter them by price, gender, or color. On the single product page, you can set the quantity and put it into the cart. In the products cart, you can edit product quantity or delete it. After all, you can go to checkout and make a payment transaction. If the payment was successful, you are redirected to the success page. Tech stack: for the backend server, NodeJS, together with Express, was used. MongoDB was my choice of database. The front end was developed with React and Sass. React Router was used for navigation, and Redux for state management. Redux Thunk and Axios manage all asynchronous tasks between the backend server and frontend page. Stripe handled payment transactions.",
        features: ['React & Node JS', 'MongoDB', 'React Router', 'Redux', 'Stripe'],
        img: './img/shop-ipadMini.png',
        link: 'https://flourishing-gumption-4b7314.netlify.app/',
        git_repo: 'https://github.com/zarta21/e-commerce-site-react'
    },
    {
        title: 'Photo Gallery',
        desc: "This online photo album was created using React for the front end and Firebase for the back end. It has a login function allows the user to log in and upload a photo. The uploaded file is sent to the server – Firebase storage. Firebase - firestore is used to get all image URLs to show them in real-time. This allows seeing the recently added images without refreshing the page. The image will appear in the full-sized modal window when you click on the image. In the modal, you  can slide to the next or previous photo by clicking on the arrow buttons, or you can click on the download button and  download the image. It has a lazy loading function, so more pictures are loaded to the image grid when you scroll down.  There is also a toggle button that allows you to change between the dark and light themes.",
        features: ['React', 'Firebase', 'Framer-Motion', 'Font Awesome'],
        img: './img/photo-gallery.png',
        link: 'https://www.myphotogallery.fun',
        git_repo: 'https://github.com/zarta21/Photo-Gallery'
    },
    {
        title: 'Lotto Statistics',
        desc: "This application allows you to explore and analyze Viking Lotto and EuroJackpot Lotto results, providing valuable insights into numbers' frequency, consecutive number pairs, hot and cold numbers, common number ranges, and changes in the sum of numbers for each game. The frontend is built using React, Sass, ChartJS, and useContext for state management, while the backend is powered by Node.js and Express, with MongoDB serving as the database.",
        features: ['React & Node JS', 'Chart JS', 'MongoDB'],
        img: './img/lotto-ipadMini.png',
        link: 'https://frabjous-pegasus-105f19.netlify.app/',
        git_repo: 'https://github.com/zarta21/Lotto-results-statistics'
    },
    {
        title: 'Crypto Tracker',
        desc: "This application offers real-time updates on cryptocurrency prices using data sourced from the CoinGecko API. Axios retrieves data seamlessly from the API, while React is used to develop the front end. The main page lists all cryptocurrencies available on the Coingecko API, complete with coin images, current values, and price changes over one and 24 hours. The search bar, powered by a filter function, enables easy coin discovery by typing in the coin's name. Clicking on a specific coin directs you to a detailed page where comprehensive historical data about the coin's pricing is presented in an interactive graph using ChartJS. To make it more convenient, a sorting function allows users to organize data based on the desired timeframe - 24 hours, 7, 14, 30 days, or one year. The integration of React-Router facilitates seamless navigation between pages.",
        features: ['React', 'React-Router', 'Chart JS', 'Axios'],
        img: './img/crypto-tracker.png',
        link: 'https://goofy-carson-b7a87d.netlify.app',
        git_repo: 'https://github.com/zarta21/Crypto-Tracker'
    },
    {
        title: 'Trivia App',
        desc: "This application was developed using React and Styled Components, offering an intuitive user interface. It has two distinct categories: CSS and Front-End. Upon selecting a category, the app dynamically retrieves questions from MongoDB, filtering them based on the chosen category. This ensures a tailored and engaging experience for the player, allowing them to focus on their preferred expertise.",
        features: ['React & Node JS', 'MongoDB', 'Styled Components'],
        img: './img/trivia.jpg',
        link: 'https://graceful-gnome-75fa02.netlify.app',
        git_repo: 'https://github.com/zarta21/Trivia'
    },
    {
        title: 'Snake Game',
        desc: "JavaScript snake game created using canvas. The game has two options: beginner and expert. The beginner level is slower, and the expert is more difficult. In both levels, snake speed increase when the snake becomes longer.",
        features: ['JavaScript', 'CSS', 'Canvas'],
        img: './img/Screenshot-Snake-Game.png',
        link: 'https://verdant-travesseiro-05e4cc.netlify.app/',
        git_repo: 'https://github.com/zarta21/Snake-game'
    },
    {
        title: 'Pomodoro Clock',
        desc: "JavaScript pomodoro clock. This is a timer for 25 minutes of work and 5 minutes for a break. The timer countdown session time, and when time ends, it plays an alarm sound. It has a session length adjustment function and a reset button.",
        features: ['JavaScript', 'CSS', 'HTML'],
        img: './img/Screenshot-Pomodoro-clock.png',
        link: 'https://65d39b3d08061f187cc02910--scintillating-lamington-44272d.netlify.app/',
        git_repo: 'https://github.com/zarta21/pomodoro-clock'
    },
]