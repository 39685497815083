import React, { useContext, useEffect, useState } from 'react'
import './themeBtn.scss'
import { AppContext } from '../../App'
import { buttonIcons } from '../../icons'

const ThemeBtn = () => {

    const theme = useContext(AppContext)

    const [darkTheme, setDarkTheme] = useState(() => (
        localStorage.getItem("DARK_MODE") || null
    ))


    useEffect(() => {
        let btn =  document.querySelector(".btn");
        if(darkTheme === "on") {
            theme.setDarkMode(true)
            btn.classList.add("active");
        } else {
            theme.setDarkMode(false)
            btn.classList.remove("active");
        }
    }, [darkTheme, theme.darkMode])

    const toggleTheme = () => {
        if (darkTheme !== "on") {
            localStorage.setItem("DARK_MODE", "on");
            setDarkTheme("on")
        } else {
            localStorage.setItem("DARK_MODE", null);
            setDarkTheme(null)
        }
    }

  return (
    <div className='theme-btn'>
        <div className="toggle">
            <input type="checkbox" className="checkbox" onChange={toggleTheme} />
            <label className="btn">
                <div className="sun">
                    {buttonIcons[1].svg}
                </div>
                <div className="moon">
                    {buttonIcons[2].svg}
                </div>
            </label>
        </div>
    </div>
  )
}

export default ThemeBtn