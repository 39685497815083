import React from 'react'
import './profile.scss'
import { skillsIcons, socialIcons } from '../../icons'

const Profile = () => {

  return (
    <div className='profile'>
        <div className="container">
            <div className="me">
                <div className="photo">
                    <div className="quote-container">
                        <i className="pin"></i>
                        <blockquote className="note sticky">
                            <img src="./img/IMG_0512.jpeg" alt="Me" loading='lazy'/>
                            <cite className="author">Hi, I'm Tautvydas!</cite>
                        </blockquote>
                    </div>
                    <div className="social">
                        {socialIcons.map((item, index) => (
                            <span key={index} title={item.name}>
                                <a href={item.url} target='_blank'>
                                    {item.svg}
                                </a>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="skills">
                    <div className="scroller">
                        <ul>
                            {skillsIcons.map((item, index) => (
                                <li key={index}>
                                    <span title={item.name}>
                                        <i>{item.svg}</i>
                                        <span>{item.name}</span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about-me">
                <div className="title">
                    <h2>About Me</h2>
                </div>
                <p>
                    <span >
                        I'm passionate about crafting exceptional web experiences as a self-taught web developer. My journey into the world of
                        web development began as a personal curiosity that quickly turned into an enduring passion.
                        With a strong determination to learn and a hunger for knowledge, I set out to teach myself how to create visually
                        appealing and user-friendly websites.
                    </span>
                    <br />
                    <span>
                        Front-end development is where I shine the brightest. I have honed my HTML, CSS, JavaScript, and React skills to transform creative ideas into interactive and responsive web
                        applications.
                        In 2021, on <a href="https://www.freecodecamp.org/"
                            target="_blank"><strong>freeCodeCamp</strong></a>,
                        I achieved Web Design, JavaScript Algorithms and Data Structures, and
                        Front-End Development certifications.
                    </span>
                    <br />
                    <span >
                        I'm always excited to take on new challenges and help turn visionary ideas into reality on the web.
                        In a world where technology and web presence are more critical than ever, I'm dedicated to making a positive impact
                        through my work.
                    </span> 
                </p>
            </div>
        </div>
    </div>
  )
}

export default Profile