import React, { useContext, useState } from 'react'
import './slider.scss'
import { projects } from '../../Projects'
import { buttonIcons, socialIcons } from '../../icons'
import { AppContext } from '../../App'

const Slider = () => {

    const project = useContext(AppContext)

    const [slide, setSlide] = useState(1)

    const nextSlide = () => {
        setSlide(slide === projects.length ? 1 : (prev) => prev + 1)
    }

    const prevtSlide = () => {
        setSlide(slide === 1 ? projects.length : (prev) => prev - 1)
    }

  return (
    <div className='slider'>
        <div className="container">
            <div className="title">
                <h2>Projects</h2>
            </div>
            {project.data.length > 0 ?
                project.data.map((item, index) => (
                    <div className={`project ${slide === index + 1 ? "active" : null}`} key={index}>
                        <div className="wrapper">
                            <div className="quote-container">
                                <i className="pin"></i>
                                <blockquote className="note sticky">
                                    <a href={item.link} target='_blank' rel="noreferrer">
                                        <img src={`https://server.tautvydas.net/assets/img/${item.img}`} alt={item.title} loading='lazy'/>
                                    </a>
                                    <cite className="author">{item.title}</cite>
                                </blockquote>
                            </div>
                            <div className="features">
                                <span>Features:</span>
                                <ul>{item.features.split(",").map(el => (
                                    <li key={el}> ●{el}</li>
                                ))}</ul>
                            </div>                            
                            <div className="description">  
                                <p>{item.des}</p>
                                <div className="buttons">
                                    <a href={item.link} target='_blank' rel="noreferrer">                                    
                                        <button className='try-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 300 300"><path fill="currentColor" d="M266.28 216.71c.68-47.38-.03-94.77-2.17-142.12-.33-7.21-11.58-7.25-11.25 0 2.15 47.53 2.86 95.1 2.16 142.67-71.61 3.72-143.32 5.21-215.02 4.43V70.11c0-7.24-11.25-7.25-11.25 0v180.87c0 7.24 11.25 7.25 11.25 0v-18.03c71.64.79 143.28-.7 214.83-4.42-.09 4.46-.16 8.93-.28 13.39-.18 7.24 11.07 7.24 11.25 0 .12-4.66.2-9.32.29-13.98 6.81-.62 6.92-11.16.19-11.23zM246.27 50.64c-64.28 2.29-128.66-.05-192.61-7-7.19-.78-7.13 10.48 0 11.25 63.95 6.96 128.32 9.3 192.61 7 7.22-.26 7.25-11.51 0-11.25z"></path></svg>
                                            Link to Try
                                        </button>
                                    </a>
                                    <a href={item.git_repo} target='_blank' rel="noreferrer" style={{ "zIndex": "2"}}>
                                        <button>{socialIcons[2].svg}</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : 
                projects.map((project, index) => (
                    <div className={`project ${slide === index + 1 ? "active" : null}`} key={index}>
                        <div className="wrapper">
                            <div className="quote-container">
                                <i className="pin"></i>
                                <blockquote className="note sticky">
                                    <a href={project.link} target='_blank' rel="noreferrer">
                                        <img src={project.img} alt={project.title} loading='lazy'/>
                                    </a>
                                    <cite className="author">{project.title}</cite>
                                </blockquote>
                            </div>
                            <div className="features">
                                <span>Features:</span>
                                <ul>{project.features.map(el => (
                                    <li key={el}> ●{el}</li>
                                ))}</ul>
                            </div>                            
                            <div className="description">  
                                <p>{project.desc}</p>
                                <div className="buttons">
                                    <a href={project.link} target='_blank' rel="noreferrer">                                    
                                        <button className='try-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 300 300"><path fill="currentColor" d="M266.28 216.71c.68-47.38-.03-94.77-2.17-142.12-.33-7.21-11.58-7.25-11.25 0 2.15 47.53 2.86 95.1 2.16 142.67-71.61 3.72-143.32 5.21-215.02 4.43V70.11c0-7.24-11.25-7.25-11.25 0v180.87c0 7.24 11.25 7.25 11.25 0v-18.03c71.64.79 143.28-.7 214.83-4.42-.09 4.46-.16 8.93-.28 13.39-.18 7.24 11.07 7.24 11.25 0 .12-4.66.2-9.32.29-13.98 6.81-.62 6.92-11.16.19-11.23zM246.27 50.64c-64.28 2.29-128.66-.05-192.61-7-7.19-.78-7.13 10.48 0 11.25 63.95 6.96 128.32 9.3 192.61 7 7.22-.26 7.25-11.51 0-11.25z"></path></svg>
                                            Link to Try
                                        </button>
                                    </a>
                                    <a href={project.git_repo} target='_blank' rel="noreferrer" style={{ "zIndex": "2"}}>
                                        <button>{socialIcons[2].svg}</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            <div className="slide-btn">                
                <div className="prev" onClick={prevtSlide}>
                    {buttonIcons[3].svg}
                </div>
                <div className="next" onClick={nextSlide}>
                    {buttonIcons[4].svg}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Slider