import React, { useEffect, useState } from 'react'
import './footer.scss'

const Footer = () => {

    const [quote, setQuote] = useState('')
    const [author, setAuthor] = useState('')

    useEffect(() => {
        const url = "https://gist.githubusercontent.com/camperbot/5a022b72e96c4c9585c32bf6a75f62d9/raw/e3c6895ce42069f0ee7e991229064f167fe8ccdc/quotes.json";

      const  getQuote = () => {
            fetch(url).then(res => res.json())
                .then(res => {
                    let quotes = res.quotes;
                    const idx = Math.floor(Math.random() * quotes.length);
                    const item = quotes[idx];
                    setQuote(item.quote)
                    setAuthor("- " + item.author)
                });    
        };
        getQuote()
    }, [])

    
  return (
    <div className='footer'>
        <div className="container">
            <div className="quote-container">
                <i className="pin"></i>
                <blockquote className="note sticky">
                    <div className="title">
                        <h2>Thought of the day</h2>
                    </div>
                    <span className="quote">{quote}</span>
                    <cite className="author"><span className="author">{author}</span></cite>
                </blockquote>
            </div>      
        </div>
    </div>
  )
}

export default Footer