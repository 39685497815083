import React, { useContext, useEffect, useRef, useState } from 'react'
import './contactMe.scss'
import { buttonIcons } from '../../icons'
import { AppContext } from '../../App'

const ContactMe = () => {

    const index = useContext(AppContext)
    const focus = useRef()

    const [inputHeight, setInputHeight] = useState("auto")
    

    useEffect(() => {
        const textarea = document.querySelector('textarea')
        textarea.addEventListener('keyup', e => {
            textarea.style.height = 'auto'
            setInputHeight(e.target.scrollHeight)
            textarea.style.height = `${inputHeight}px`
        })
    }, [inputHeight])


    useEffect(() => {
        if(index.index === 2) {
            focus.current.focus()
        }
    }, [index.index])


  return (
    <div className='contact-me'>
        <div className="container">
            <div className="title">
                <h2>Write me</h2>
            </div>
            <div className="quote-container">
                <i className="pin"></i>
                <blockquote className="note sticky">
                    <form action="https://formsubmit.co/8237951e02b514a8967251d5208e3a6a" method="POST">
                        <input type="hidden" name="_next" value="https://tautvydas.net/" />
                        <input type="hidden" name="_autoresponse" value="Thank you for your message. I will get back to you as soon as possible. Best regards, Tautvydas."></input>
                        <div className="contact-form">
                            <div className='contact-info'>
                                <label className="contact-label" htmlFor="email">Email:</label>
                                <input className="contacts" type="email" name="email" placeholder="email@email.com" required ref={focus} style={{"font-size": "20px"}} />
                            </div>
                            <div className='contact-info'>
                                <label className="contact-label" htmlFor="name">Name:</label>
                                <input className="contacts" type="text" name="name" placeholder="Name" required style={{"font-size": "20px"}} />
                            </div>
                            <div>
                                <label className="contact-label" htmlFor="message">Message:</label>
                                <textarea className="textarea" name="message" placeholder="Type something..." required style={{"font-size": "20px"}}></textarea>
                            </div>
                        </div>
                        <cite className="author">
                            <button type='submit'>
                                <span>Send</span>
                                <span className='icon'>
                                    {buttonIcons[0].svg}
                                </span>
                            </button>
                        </cite>
                    </form> 
                </blockquote>
            </div>
        </div>
        
    </div>
  )
}

export default ContactMe